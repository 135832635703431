import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PUBLIC_RESERVATIONS_ONLINE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export default class PublicReservationsPartnerOnline extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    static defaultProps = {
    };

    state = {}

    render() {
        const { history, location } = this.props;
        const { search } = location;

        const queryObject = parseQueryToObject(search, true);

        return (
            <StyledComponent
                className={classnames({
                    'component-reservations-partner-online': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                <div className="content content-reservation-information">
                    <LayoutContainer>
                        <div className="body">
                            <div className="section introduction">
                                <h1 className="headline">Zarezerwuj swój pierwszy trening w FitAdept</h1>
                                <h3 className="subheadline">
                                    Dziękujemy za zakup pakietu treningowego <span style={{ color: 'red' }}>FitAdept</span> przy współpracy z <span style={{ color: 'red' }}>Fitness Catering</span>.<br />
                                    Bardzo się cieszymy, że zdrowe odżywianie chcesz uzupełnić o regularne treningi!<br /><br />
                                    Za Tobą pierwszy i najważniejszy krok. Razem z zespołem będziemy czuwać nad osiąganymi systematycznie kolejnymi krokami.<br />
                                    Chcemy by spacer zamienił się w trucht, potem w bieg a może jeśli zechcesz nawet w sprint!<br /><br />

                                    FitAdept to największa w Polsce społeczność certyfikowanych trenerów personalnych, fizjoterapeutów i pasjonatów sportu.<br /><br />

                                    Kliknij poniższy przycisk abyś został przekierowany na stronę rezerwacji pierwszego treningu FitAdept. Wybierz dogodny termin pierwszego spotkania.<br />
                                    Resztą zajmiemy się już my :)
                                </h3>
                            </div>
                            <div className="section cta">
                                <Button 
                                    onClick={() => history.push(withVariables(PUBLIC_RESERVATIONS_ONLINE.path, {}, { code: queryObject.code }))}
                                >
                                    Przejdź do rezerwacji {'>>>'}
                                </Button>
                            </div>
                            <div className="section specific-information">
                                <p className="specific-information-text">
                                    Pierwszy trening to trening diagnostyczny - 60-minutowa indywidualna lekcja i ćwiczenia 1:1 za pośrednictwem kamerki w telefonie, komputerze, smart-TV.<br /><br />
                                    Podczas treningu zbadamy Twoją dotychczasową aktywność fizyczną, żywienie i zdrowie. Wykonamy też testy sprawnościowe.<br /><br />
                                    Trener pomoże Ci wyznaczyć realne cele treningowe, określi ramy czasowe ich realizacji, częstotliwość treningów oraz zarysuje plan ćwiczeń.<br /><br />
                                    Umówisz się również na kolejne treningi.
                                </p>
                            </div>
                        </div>
                    </LayoutContainer>
                </div>
            </StyledComponent>
        );
    }
}
